.header{
    background: #591019;
    border-bottom: 20px solid white;
    min-height: 200px;
    border-bottom-left-radius: 350px;
    border-bottom-right-radius: 350px;
    color: white;
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}