.button{
    background: #591019 !important;
    color: white !important;
    width: 80%;
    text-align: center;
    margin: 30px 0;
    border-radius: 20px !important;
}

.button:hover{
    background: #611049 !important;
}